$gray-100: #f3f3f3;
$gray-125: #f4f6f9;
$gray-150: #ebeef4;
$gray-200: #d9dde3;
$gray-300: #ced6e3;
$gray-400: #c2c2c2;
$gray-450: #b8b8b8;
$gray-475: #b3bfd1;
$gray-525: #9facc1;
$gray-550: #8e939a;
$gray-600: #718098;
$gray-620: #6a6d73;
$gray-625: #5b6778;
$gray-650: #555555;
$gray-675: #475160;
$gray-700: #414347;
$gray-750: #3c3c3c;
$gray-800: #303947;
$gray-850: #29303b;
$gray-900: #1b1f26;
$gray-950: #191c21;

$black: #000;
$blue:   #2b82fc;
$purple: #5e3084;
$light-purple: #9764f5;
$green:  #5ac072;
$cyan:   #83c6e9;
$white: #fff;
$orange: #ffb500;
$red: #d83e3e;

$light: $gray-200;
$light-blue: #d6edff;
$light-blue-1: #D1DAEA;
$lighter-blue: #6486f5;
$lightest-blue: #e3e9ff;
$lighter-purple: #9764f5;
$lightest-purple: #f1eafd;

$colors: (
  "gray-dark":  $gray-900
);

$danger: $red !default;

$dark: $gray-900;

$border-radius: 4px;

$font-weight-semi-bold: 500;
$font-weight-bold: 700;
$font-size-base: .875rem;
$font-family-sans-serif: Graphik, sans-serif;

$h1-font-size: $font-size-base * 2.57;
$h5-font-size: 1.125rem;
$h6-font-size: 0.75rem;

$input-border-color: $gray-100;
$input-color: $gray-900;
$input-bg: $gray-125;
$input-padding-y: .625rem;
$input-padding-x: .75rem;
$input-focus-border-color: $input-border-color;

$input-btn-padding-y: .625rem;
$input-btn-padding-x: 1.125rem;
$input-btn-line-height: 1;
$input-btn-border-width: 1px;

$input-btn-padding-y-lg: .8125rem;
$input-btn-padding-x-lg: 1.125rem;
$input-btn-line-height-lg: 1.45;
$input-btn-focus-width: 0;
$input-btn-focus-color: $input-border-color;
$custom-radio-indicator-icon-checked: url('data:image/svg+xml,<svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group-9" fill="#2B82FC"><circle id="Oval-Copy" cx="12.5" cy="12.5" r="12.5"></circle><circle id="Oval" stroke="#FFFFFF" cx="12.5" cy="12.5" r="10"></circle></g></g></svg>') !default;

$input-btn-font-size-lg: $font-size-base;

$btn-font-weight: $font-weight-semi-bold;
$btn-font-size-lg: $font-size-base;
$btn-border-radius-lg: $border-radius;
$btn-focus-box-shadow: none;

$input-padding-y-lg: .8125rem;

$input-group-addon-bg: $input-bg;

$nav-link-padding-y: .625rem;
$nav-link-padding-x: .625rem;

$navbar-padding-y: .625rem;
$navbar-padding-x: 0;

$card-spacer-y: 2.5rem;
$card-spacer-x: 2.5rem;
$card-border-width: 0;
$card-border-radius: 0;

$modal-header-padding-y: 2.25rem;

$modal-inner-padding: 0;

$modal-content-border-width: 0;
$modal-content-border-radius: 1px;
$modal-content-horiz-padding: 75px;

$modal-md: none;
$modal-backdrop-bg: rgba($black, .16);

$modal-sm: 450px;

$alert-border-width: 0;
$alert-bg-level: 0;

$breadcrumb-padding-y: 1rem;
$breadcrumb-padding-x: 0;
$breadcrumb-active-color: $gray-950;
$breadcrumb-divider-color: $gray-550;
$breadcrumb-bg: $white;
$breadcrumb-divider: quote(">");

$badge-font-size: 85%;
$badge-padding-y: .5em;
$badge-pill-padding-x: .5em;
$badge-font-weight: 600;

$table-border-color: $gray-150;
$table-head-bg: $gray-125;
$table-head-color: $gray-550;

$pagination-border-width: 0;
$pagination-active-color: $blue;
$pagination-active-bg: $light-blue;

$dropdown-padding-y: .5rem;
$dropdown-item-padding-y: .5rem;
$dropdown-item-padding-x: 1.125rem;
$dropdown-header-color: $gray-950;

$order-item-padding-r: 62px;
