.group-actions {
	margin-top: 0.5rem;
	padding-left: 0.75rem;
}

.glasses-group {
	border-radius: 4px;

	&:first-child {
		margin-top: 0;
	}
}

.order-group {
	margin-bottom: 24px;

	.order-group-heading {
		padding-top: 0.375rem;
		padding-bottom: 0.625rem;
		margin-left: 1.5rem;
		margin-bottom: 0;
	}
}

.order-item {
	padding-left: 12px;
	padding-right: $order-item-padding-r;
}

.order-item-label {
	right: $order-item-padding-r / 2;
	position: absolute;
	top: 50%;

	&::before, &::after {
		top: 0;
		left: 0;
		transform: translate(-50%, -50%);
	}
}

.order-item-label::before {
	border-color: #adb5bd !important;
}
