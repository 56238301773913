#order-lens-modal {
  .repeat-reason-box {
	background-color: $gray-125;
	padding: 24px 18px;
	margin-bottom: 24px;
	.title {
	  display: flex;
	  align-items: center;
	  margin-bottom: 18px;
	  h6 {
		text-transform: uppercase;
		margin: 0 24px 0 0;
	  }
	}
	.btn-floating {
	  border: 1px solid #dadeda;
	}
	.custom-select {
		background-color: $white;
	}
	.form-control, .custom-date-picker input{
		background-color: $white;
	}
  }
}
