.btn-white {
  @include button-variant($white, $white, $gray-100, $gray-100, $gray-100, $gray-100);
}

.custom-radio-button {
  &.btn-group {
    .btn:not(:last-child):not(.dropdown-toggle) {
      border-top-right-radius: $btn-border-radius;
      border-bottom-right-radius: $btn-border-radius;
    }

    .btn:not(:first-child) {
      border-top-left-radius: $btn-border-radius;
      border-bottom-left-radius: $btn-border-radius;
    }
  }

  .btn {
    position: relative;
    padding-left: 40px;
    font-weight: $font-weight-base;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      border: 1px solid $gray-400;
      border-radius: 50%;
      background-color: $white;
      top: 50%;
      left: 6px;
      transform: translate(0, -50%);
    }

    &:not(:last-child) {
      margin-right: 2px;
    }

    &:focus,
    &.focus {
      box-shadow: none !important;
    }
  }

  .active {
    &::after {
      background-color: $primary;
      border-color: $primary;
    }

    &::before {
      background: no-repeat 50% / 90% 100%;
      background-image: escape-svg($custom-radio-indicator-icon-checked);
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      left: 7px;
      width: 22px;
      height: 22px;
      z-index: 1;
    }
  }
}

.custom-radio-button--location-selector {
  .btn {
    padding-left: 60px;
    border-radius: 0;

    &::after {
      width: 22px;
      height: 22px;
      border: none;
      left: 24px;
    }

    &::before {
      background-image: escape-svg($custom-checkbox-indicator-icon-checked);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 12px;
      left: 24px;
      font-size: 10px;
    }

    &:not(:last-child) {
      margin-right: 0;
    }
  }
}

.custom-radio-tag {
  margin-left: -3px;

  .btn {
    padding: 0.625rem 0.25rem;
    min-width: 3rem;
    height: 3rem;
    border-radius: 50%;
    text-transform: uppercase;
    border: 2px solid transparent;
    background-color: transparent;
    line-height: 1.3;
    font-size: 0.875rem;
    text-align: center;
    color: $gray-700;

    &:not(:last-child):not(.dropdown-toggle) {
      border-radius: 1.5rem;
      margin-right: 0.625rem;
    }

    &:not(:first-child) {
      border-radius: 1.5rem;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      right: 3px;
      bottom: 3px;
      left: 3px;
      border-radius: 1.5rem;
      border: solid 1px $gray-200;
    }

    span {
      position: relative;
      z-index: 1;
      font-size: $font-size-sm;
      font-weight: 600;
      line-height: 2;
      padding: 0 10px;
    }
  }

  .btn-primary:not(:disabled):not(.disabled).active {
    background: transparent;
    border-color: $primary;

    &::after {
      border-color: $primary;
      background-color: $primary;
    }
  }

  .btn-purple:not(:disabled):not(.disabled).active {
    background: transparent;
    border-color: $light-purple;
    color: $white;

    &::after {
      border-color: $light-purple;
      background-color: $light-purple;
    }
  }
}

.btn-outline-light {
  color: $gray-620;
}

.btn-white-outline-light {
  background-color: white;
  color: $gray-620;
  border: 1px solid $gray-200;

  &:hover,
  &.hover {
    background-color: $gray-620;
    color: white;
  }

  &:disabled,
  &.disabled {
    color: $gray-620;
    background-color: white;
    border-color: $gray-200;
    pointer-events: none;
  }
}

.btn-icon {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  padding: 0.25rem;
  border-radius: 50%;
  background-color: $gray-150;
  line-height: 0;
  font-size: 0.75rem;
  text-align: center;

  &:hover,
  :focus,
  &:active {
    background-color: darken($gray-150, 5%);
    color: darken($gray-900, 5%);
  }

  &::before {
    position: absolute;
    content: '';
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }
}

.btn-icon--collapse {
  background-color: $gray-700;
  color: $white;

  &:hover,
  :focus,
  &:active {
    background-color: lighten($gray-700, 5%);
    color: darken($white, 5%);
  }

  &.collapsed {
    background-color: $gray-150;
    color: $gray-700;

    &:hover,
    :focus,
    &:active {
      background-color: darken($gray-150, 5%);
      color: lighten($gray-700, 5%);
    }
  }
}

.btn-icon--priority {
  border-radius: 5px;
  border: 2px solid #979797;
  background-color: #fff;
  background-size: 27px;
  padding: 0;
  background-position: center center;
  &:hover,
  :focus,
  &:active {
    background-color: #fff;
  }

  &.urgent {
    border-color: $red;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTI4cHgiIGhlaWdodD0iMTI4cHgiIHZpZXdCb3g9IjAgMCAxMjggMTI4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPkhpZ2hlc3QgLSBzcXVhcmUgLSBSZWQ8L3RpdGxlPgogICAgPGcgaWQ9IkhpZ2hlc3QtLS1zcXVhcmUtLS1SZWQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIGZpbGw9IiNEODNFM0UiIHg9IjAiIHk9IjAiIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiByeD0iMTAiPjwvcmVjdD4KICAgICAgICA8ZyBpZD0iaWNvbi0vLW5hdi0vLWRvd24iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDU3LjAwMDAwMCwgMjguMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+CiAgICAgICAgICAgIDxnIGlkPSJub3VuX0FsZXJ0XzE2MDYyNzJfMDAwMDAwIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik03LDcyIEMzLjQyMTE5NDgyLDcyIDAuNTIsNjkuMDk4ODA1MiAwLjUyLDY1LjUyIEMwLjUyLDYxLjk0MTE5NDggMy40MjExOTQ4Miw1OS4wNCA3LDU5LjA0IEMxMC41Nzg4MDUyLDU5LjA0IDEzLjQ4LDYxLjk0MTE5NDggMTMuNDgsNjUuNTIgQzEzLjQ4LDY5LjA5ODgwNTIgMTAuNTc4ODA1Miw3MiA3LDcyIFoiIGlkPSJQYXRoIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTMuNDgsNDMuOTIgQzEzLjQ4LDQ3LjQ5ODgwNTEgMTAuNTc4ODA1MSw1MC4zOTk5OTk3IDcsNTAuMzk5OTk5NyBDMy40MjExOTQ5Myw1MC4zOTk5OTk3IDAuNTIsNDcuNDk4ODA1MSAwLjUyLDQzLjkyIEwwLjUyLDYuNDggQzAuNTIsMi45MDExOTQ4MiAzLjQyMTE5NDgyLDAgNywwIEMxMC41Nzg4MDUyLDAgMTMuNDgsMi45MDExOTQ4MiAxMy40OCw2LjQ4IEwxMy40OCw0My45MiBaIiBpZD0iUGF0aCI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
  }
}

.btn-icon--select {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #979797;
  background-color: #fff;
  padding: 0;
  &:hover,
  :focus,
  &:active {
    background-color: #fff;
  }

  &.selected {
    background-position: center center;
    background-color: $blue;
    background-repeat: no-repeat;
    background-size: 14px;
    background-image: escape-svg($custom-checkbox-indicator-icon-checked);
  }
}

.btn-icon-text-transparent {
  padding: 0.375rem 0;
  color: $gray-620;

  .icon {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.2rem;
    padding: 0.25rem;
    border-radius: 0.25rem;
    background-color: $gray-150;
    color: $gray-700;
    &.active {
      background-color: $gray-800;
      color: #fff;
    }
  }
}

.btn-lg {
  font-weight: $font-weight-semi-bold;
}

.btn-xl {
  height: 64px;
}

.btn-filter.collapsed {
  background-color: transparent;
  color: $gray-550;

  i {
    color: $gray-550;
  }
}

.btn-filter {
  background-color: rgba(0, 0, 0, 0.03);

  i {
    color: $primary;
  }
}

.btn-clear-filter {
  color: $gray-620;
  padding-right: 0;
  i {
    color: $gray-700;
    position: relative;
    font-size: 10px;
    top: -2px;
    padding: 7px;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 4px;
  }
}

.custom-switch {
  padding-left: 4.25rem;

  .custom-control-label {
    line-height: 2.25rem;
    font-size: $font-size-base;
    font-weight: $font-weight-semi-bold;
    color: $gray-950;

    &::before {
      top: 0;
      left: -4.25rem;
      width: 3.75rem;
      height: 2.25rem;
      border-width: 0;
      border-radius: 1.125rem;
      background-color: $gray-150;
    }

    &::after {
      top: 4px;
      left: calc(-4.25rem + 4px);
      width: calc(2.25rem - 8px);
      height: calc(2.25rem - 8px);
      border-radius: 50%;
      background-color: $white;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(1.5rem);
  }
}

.no-label {
  margin-top: 1.375rem;
}

.btn-floating {
  position: relative;
  padding-top: 1.5rem;
  padding-left: 0.75rem;
  padding-bottom: 0.25rem;
  background-color: $white;
  border: none;
  font-weight: $font-weight-normal;
  text-align: left;

  &:after {
    position: absolute;
    right: 12px;
    bottom: 12px;
  }

  .btn__label {
    position: absolute;
    top: 0.25rem;
    left: 0.75rem;
    color: $gray-600;
    font-size: 12px;
  }

  .btn__value {
    max-width: 90%;
    display: block;
    overflow: hidden;
  }
}

.btn--badge-pill {
  @extend .badge;
  @extend .badge-pill;
}

.btn {
  &.well {
    background-color: $gray-125;
  }
}
