.status-dropdown {
  button {
	@include badge;
	&:after {
	  width: 10px;
	  height: 6px;
	  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iN3B4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDcgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNjEuMSAoODk2NTApIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPmRyb3Bkb3duLWFycm93PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9ImRyb3Bkb3duLWFycm93IiBmaWxsPSIjRkZGRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iPgogICAgICAgICAgICA8cGF0aCBkPSJNMC40NTI1MTI5LDMuMjUyNTEzMTkgTDMuNSwwLjIwNTAyNTgxMiBMNi41NDc0ODcxLDMuMjUyNTEzMTkgQzYuNjg0MTcxMiwzLjM4OTE5NjczIDYuNjg0MTcxMiwzLjYxMDgwNDQxIDYuNTQ3NDg3MSwzLjc0NzQ4Nzk1IEM2LjQyNTk5MDksMy44Njg5ODQzNiA2LjIzNzM5NDEsMy44ODI0ODQgNi4xMDA5ODU4LDMuNzg3OTg2NzMgTDYuMDUyNTEyOSwzLjc0NzQ4Nzk1IEwzLjUsMS4xOTQ5MDA1NyBMMC45NDc0ODcxLDMuNzQ3NDg3OTUgQzAuODI1OTkwOSwzLjg2ODk4NDM2IDAuNjM3Mzk0MSwzLjg4MjQ4NCAwLjUwMDk4NTgsMy43ODc5ODY3MyBMMC40NTI1MTI5LDMuNzQ3NDg3OTUgQzAuMzMxMDE2LDMuNjI1OTkxNDcgMC4zMTc1MTY1LDMuNDM3Mzk0MzkgMC40MTIwMTM3LDMuMzAwOTg2NzIgTDAuNDUyNTEyOSwzLjI1MjUxMzE5IFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMuNTAwMDAwLCAyLjAyNzUxMykgc2NhbGUoLTEsIDEpIHJvdGF0ZSgtMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC0zLjUwMDAwMCwgLTIuMDI3NTEzKSAiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==");
	  background-position: center center;
	  background-repeat: no-repeat;
	  background-size: contain;
	  border: none;
	  position: relative;
	  top: 2px;
	  right: -2px;
	}
	&:hover {
	  color: #fff;
	}
  }

  .btn-orange {
	background-color: $orange;
  }
  .btn-blue {
	background-color: $blue;
  }
  .btn-green {
	background-color: $green;
  }

  .dropdown-menu {
	box-shadow: 0 8px 24px 0 rgba(25, 28, 33, 0.15);
	button {
	  color: $table_color;
	  padding: 12px 8px;
	}
  }
}
