.modal-dialog {
  margin-top: 0;
}

.modal-title {
  position: relative;

  .close {
    position: absolute;
    top: -10px;
    right: -1rem;
    font-size: 13px;
    padding: 1rem;
    margin: 0;
  }
}

.modal-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 1.125rem 0;
  background-color: $gray-150;
  z-index: 100;
}

.modal-header--dialog {
  padding: 1.125rem;
  border-bottom: solid 1px $gray-150;

  .modal-title {
    font-size: 1rem;
  }
}

.modal-body--dialog {
  padding: 2.25rem;
  text-align: center;
}

.modal-body--dialog-form {
  padding: 24px 60px;

  legend {
    font-size: 12px;
    color: $gray-620;
  }
}

.modal-footer--dialog {
  padding-top: 0;
  background-color: $white;
  text-align: center;
}

.modal-content--location {
  width: 450px;
  margin-left: auto;
  margin-right: auto;
}

.modal-content-container {
  padding: 0 72px 36px;
}

.modal-footer-content {
  padding-left: $modal-content-horiz-padding;
  padding-right: $modal-content-horiz-padding;
}

@media (max-width: 576px) {
  .modal-content-container {
    padding: 0 36px;
  }
}
