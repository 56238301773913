.error {
	display: inline-block;
	position: relative;
	color: $red;
	background: url("../icons/error.svg") no-repeat left center;
	padding: 3px 0 3px 25px;
	margin-top: 8px;
	margin-bottom: 8px;
	font-size: 12px;
	border-radius: 4px;

	&.error-tooltip {
		@include error-danger-box;

		&:before {
			content: " ";
			position: absolute;
			top: -5px;
			left: 13px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 3px 5px 3px;
			border-color: transparent transparent $red transparent;
		}
	}

	&.error-box {
		@include error-danger-box;
	}
}

.warning {
	display: inline-block;
	position: relative;
	background: url("../icons/warning.svg") no-repeat left center;
	padding: 3px 0 3px 25px;
	margin-top: 8px;
	margin-bottom: 8px;
	font-size: 12px;
	border-radius: 4px;

	&.warning-tooltip {
		@include error-danger-box;

		&:before {
			content: " ";
			position: absolute;
			top: -5px;
			left: 13px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 3px 5px 3px;
			border-color: transparent transparent $yellow transparent;
		}
	}

	&.warning-box {
		@include error-danger-box;
	}
}
