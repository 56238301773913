.nav-wrapper {
  background-color: $gray-800;
}

.nav--root {
  padding: .5rem 1.8rem;
  margin-left: -15px;
  margin-right: -15px;
  background-color: $gray-800;
  font-weight: $font-weight-semi-bold;

  .nav-link {
    padding: 0.75rem;
    color: $gray-525;

    &:hover, &:focus {
      color: $white;
    }

    &.active {
      color: $white;
    }
  }
}

.nav--customers {
  .nav-item:first-child {
    .nav-link {
      margin-left: 0;
    }
  }

  .nav-link {
    color: $gray-550;
    padding-left: 0;
    padding-right:0;

    margin-left: .75rem;
    margin-right: .75rem;

    .badge-secondary {
      color: $gray-700;
      background-color: $gray-125;
      transition: none;
    }

    &:hover, &:focus {
      color: $gray-900;

      .badge-secondary {
        color: $primary;
        background-color: $light-blue;
      }
    }
  }

  .active {
    border-bottom: 2px solid lighten($primary, 5%);
    font-weight: $font-weight-semi-bold;
    color: $gray-900;

    .badge-secondary {
      color: $primary;
      background-color: $light-blue;
    }
  }
}
