.jobs-table {
  thead {
    th {
      padding: 1.1rem 0.4rem;

      &:nth-of-type(1) {
        padding: 1.1rem 0.4rem;
        width: 50px;
      }

      &:nth-of-type(2) {
        width: 120px;
      }

      &:nth-of-type(3) {
        width: 60px;
        text-align: center;
        padding: 1.1rem 0;
      }

      &:nth-of-type(4) {
        width: 120px;
      }

      &:nth-of-type(6) {
        width: 18%;
      }
      &:nth-of-type(7) {
        width: 14%;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 1.1rem 0.4rem;
        &:nth-of-type(1) {
          padding: 1.1rem 0.4rem;
          text-align: center;
        }
        &:nth-of-type(3) {
          text-align: center;
        }
      }

      &.b-table-details {
        td {
          padding: 0;
          text-align: left;
        }
      }
    }
  }
}


.newsletter-table {
  thead {
    th {
      padding: 1.1rem 0.4rem;

      &:nth-of-type(1) {
        padding: 1.1rem 0.4rem;
        width: 50px;
      }

      &:nth-of-type(2) {
        width: 120px;
      }

      &:nth-of-type(3) {
        width: 60px;
        padding: 1.1rem 0;
      }

      &:nth-of-type(4) {
        width: 120px;
      }

      &:nth-of-type(6) {
        width: 18%;
      }
      &:nth-of-type(7) {
        width: 14%;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 1.1rem 0.4rem;
        &:nth-of-type(1) {
          padding: 1.1rem 0.4rem;
        }
        &:nth-of-type(3) {
          text-align: center;
        }
      }

      &.b-table-details {
        td {
          padding: 0;
          text-align: left;
        }
      }
    }
  }
}
