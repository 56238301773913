.dropdown-header {
  padding-bottom: 1rem;
}

.dropdown-item.text-light {
  color: $gray-550 !important;
}

.dropdown-toggle-no-caret:after {
  display: none;
}

.dropdown-menu--location-selector {
  min-width: 18.75rem;
}

.dropdown-toggle--location-selector {
  padding: .3125rem .75rem .0625rem;
  border-radius: 1.1875rem;
  background-color: $gray-800;
  font-weight: $font-weight-semi-bold;

  .di-location-pin-24 {
    font-size: 1.25rem;
    vertical-align: -2px;
    margin-right: 12px;
  }

  .di-arrow-down-12 {
    font-size: .75rem;
    margin-left: 12px;
  }
}

.show {
  .dropdown-toggle--location-selector {
    background-color: $primary;
  }
}

.dropdown-menu--location {
  min-width: 240px;
}

.dropdown-menu--status {
  min-width: 270px;

  li {
    margin-top: 0 !important;
  }
}

.dropdown-group {
  padding-top: 10px;
}

.dropdown-group__title {
  padding-left: 18px;
  padding-right: 18px;
  font-size: 12px;
  color: $gray-550;
}

.dropdown-menu {
  border: none;
  border-radius: 4px;
  box-shadow: 0 8px 24px 0 rgba(25, 28, 33, 0.6);
  &:focus {
	outline: none;
  }
}
