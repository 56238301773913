
@import '@/assets/scss/variables.scss';

.lh-md {
  line-height: 22px;
}

.courier-selection {
  background-color: $gray-125;
  border-radius: 4px;
  margin-bottom: 18px;
}

.courier-header {
  border-bottom: solid 1px #dce0e6;
  height: 64px;
  padding: 20px 24px;

  label {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    letter-spacing: normal;
    color: $gray-950;
  }
}

.header-label {
  position: absolute;
  right: 24px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 25px;
  letter-spacing: normal;
  color: $gray-950;
}

.courier-body {
  min-height: 120px;
  padding: 24px;
}

.custom-control-label--courier {
  left: 42px;

  &::before,
  &::after {
    top: 0;
    left: -42px;
  }
}
