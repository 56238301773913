.jobs-page-holder {
  position: relative;

  .jobs {
    .table.b-table {
      .comments-button {
        padding: 0;
        position: absolute;
        right: 20px;
        top: 9px;
      }
    }

    .jobedit-open {
      position: relative;

      #order-item-detail {
        z-index: 99;
      }
    }

    .z-index-detail {
      z-index: 99;
    }
  }
}

.app-holder {
  &:before {
    content: " ";
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 4;
    transition: all ease .3s;
    visibility: hidden;
    opacity: 0;
  }

  &.modal-active {
    &:before {
      visibility: visible;
      opacity: 1;
    }
  }
}

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.modal-custom-header {
  background-color: #f4f6f9;
}