@mixin user-avatar {
  margin-right: 12px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(43, 130, 252, .1);
  border-radius: 50%;
  font-size: 12px;
  font-weight: 600;
  color: $blue;
  overflow: hidden;
  img {
	border-radius: 50%;
	width: 36px;
	height: 36px;
  }
}

@mixin badge {
  color: #fff;
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f4f6f9;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d2d7da;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #d2d7da;
  }
}

@mixin error-danger-box {
  color: #fff;
  background: url("../icons/error-white.svg") $red no-repeat center left 12px;
  padding: 8px 12px 8px 40px;
}
