.comments-list {
  .single-comment {
	display: flex;
	align-items: flex-start;
	margin-bottom: 36px;
	.user-avatar {
	  @include user-avatar;
	  margin-right: 18px;
	}
	.comment-holder {
	  width: 100%;
	  .comment-user {
		font-size: 14px;
		b {
		  font-weight: 500;
		  margin-right: 8px;
		}
		span {
		  font-size: 12px;
		  color: $gray-550;
		}
	  }
	  .comment-time {
		font-size: 12px;
		color: $gray-550;
		margin-bottom: 8px;
	  }
	  .comment-body {
		margin-bottom: 15px;
	  	white-space: pre-wrap;
	  }
	  .action-button {
		font-size: 12px;
		color: #939692;
		text-decoration: underline;
		margin-right: 18px;
	  }
	}
  }
}
.no-comments-alert {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 200px;
  figure {
	background-color: #f3f4f3;
	width: 126px;
	height: 126px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin-bottom: 18px;
	img {
	  width: 70px;
	}
  }
  h4 {
	font-size: 14px;
	margin-bottom: 12px;
  }
  p {
	font-size: 14px;
	color: #878b87;
  }
}
