
// Magnifying glass options
$border-size: 5px; // Modify the border width of the magnifying glass component
$border-color: #666666; // Modify the border color of the magnifying glass component
$magnifier-width: 150px; // Modify the width of the magnifying glass component
$magnifier-height: 150px; // Modify the height of the magnifying glass component

// Define your responsive sizes of
$sizes: (
  '(max-width: 320px)' 250px 250px,
  '(max-width: 480px)' 350px 350px,
  '(min-width: 481px)' 450px 450px,
  '(min-width: 1024px)' 550px 550px,
  '(min-width: 1280px)' 600px 600px
);

.vue-magnifier-container {
  position: relative;

  .preview {
    position: relative;
    background: {
      repeat: no-repeat;
      size: contain;
      position: 50% 50%;
    }
    display: block;
    clear: both;
    margin: 0 auto;
    cursor: none;

    .magnifying-glass {
      position: absolute;
      border: $border-size solid $border-color;
      border-radius: 50%;
      cursor: none;
      width: $magnifier-width;
      height: $magnifier-height;
      transform: translate((-1 * $magnifier-width/2), (-1 * $magnifier-width/2));
      background: #fff no-repeat;
      display: none;
      pointer-events: none;
      background-size: 500%;
    }

    &:hover {
      .magnifying-glass {
        display: block;
      }
    }

    @each $breakpoint in $sizes {
      $query: nth($breakpoint, 1);
      $bpWidth: nth($breakpoint, 2);
      $bpHeight: nth($breakpoint, 3);

      @media only screen and #{$query} {
        width: $bpWidth;
        height: $bpHeight;
      }
    }
  }
}
