.navbar-wrapper {
  background-color: $dark;
  padding: 0 1.8rem;
}

.input-group--dark {
  .input-group-prepend {
    color: $white;
  }

  .input-group-text, .form-control {
    background-color: $gray-750;
    border-color: $gray-750;
    color: $white;
  }
}

.input-group--no-label {
  margin-top: 21px;
}

.navbar-brand {
  padding: 0;
}

.navbar-profile-img {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  border: 2px solid $white;
  overflow: hidden;
}
