label {
  margin-bottom: .25rem;
  font-size: $font-size-sm;
  color: $gray-620;
}

.form-group {
  margin-bottom: 1.125rem;
}

.vue-tel-input--custom {
  border: none !important;
  line-height: $input-btn-line-height;

  .vti__dropdown,
  .vti__input {
    background-color: $gray-100;
    border: 1px solid #f3f3f3;
  }

  .vti__dropdown,
  .vti__input {
    padding: $input-padding-y $input-padding-x;
    border-radius: $btn-border-radius;
    border-width: $input-border-width;
  }

  .vti__dropdown {
    margin-right: .5rem;
  }

  .vti__selection {
    font-size: $font-size-base;
  }

  .vti__country-code {
    margin-right: .25rem;
  }
}


.form-control--dark,
.form-control--dark:focus {
  background-color: $gray-675;
  border-color: $gray-675;
  color: $white;
}

.b-dropdown-form {
  padding: 0 1.125rem;
}

.input-group {
  .input-group-append--has-space {
    margin-left: 12px;

    .btn {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
.input-axs::-webkit-outer-spin-button,
.input-axs::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
.input-axs[type=number] {
  -moz-appearance: textfield;
}

.custom-date-picker {
  input {
    @extend .form-control;
  }

  &.custom-date-picker--lg input {
    @extend .form-control-lg;
  }

  .vc-popover-caret {
    display: none;
  }

  .vc-container {
    --day-content-width: 36px;
    --day-content-height: 36px;
    --day-content-margin: 4px;
    --dot-diameter: 4px;
    --header-padding: 18px;
    --weeks-padding: 20px;
    font-family: inherit;
  }

  .vc-title {
    font-size: 16px;
  }

  .vc-weeks {
    padding-top: 0;
  }

  .vc-weekday {
    font-size: 12px;
    font-weight: $font-weight-semi-bold;
  }

  .vc-day-content {
    font-weight: $font-weight-normal;

  }

  .vc-day-content.vc-text-blue-900 {
    color: $white;
  }

  .vc-day .vc-opacity-0 {
    opacity: 1;
    color: $gray-550;
  }

  .vc-day .vc-pointer-events-none {
    pointer-events: all;
  }

  .vc-highlight {
    width: 44px;
    height: 36px;
    background-color: $primary;
  }

  .vc-highlight.vc-rounded-full {
    width: 36px;
  }

  .vc-highlight.vc-border-2 {
    border: none;
  }

  .vc-arrows-container {
    .vc-text-gray-600 {
      color: $gray-950;
    }
  }
}

.placeholder--black::placeholder {
  color: $gray-950 !important;
}

.custom-control {
  padding-left: 36px;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 50%;
}

.custom-checkbox-button {
  margin-bottom: 5px;
}

.custom-control-label {

  &::before,
  &::after {
    top: 20px;
    left: -36px;
    width: 24px;
    height: 24px;
  }
}

.custom-checkbox-button .custom-control-label {

  &::before,
  &::after {
    top: -3px;
    left: -36px;
    width: 24px;
    height: 24px;
  }
}

.custom-checkbox--courier {
  @extend .custom-checkbox;

  & .custom-control-input:indeterminate~.custom-control-label {
    &::before {
      background-color: rgb(244, 246, 249);
      border-color: rgb(173, 181, 189);
    }

    &::after {
      background-image: none;
    }
  }
}

.custom-radio {
  .custom-control-label {
    height: 22px;
    position: relative;
    width: 100%;
  }

  .custom-control-input:checked~.custom-control-label:after {
    background: no-repeat 50% / 90% 100%;
    background-image: escape-svg($custom-radio-indicator-icon-checked);
  }
}

.comment-form {
  display: flex;
  align-items: flex-start;

  .user-avatar {
    @include user-avatar;
  }

  .form-holder {
    width: 100%;

    textarea {
      max-height: 36px;
      transition: max-height ease-out .3s;

      &:focus {
        max-height: 72px;
        transition: max-height ease-out .3s;
      }
    }

    .form-actions {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      max-height: 0;
      overflow: hidden;
      transition: max-height ease-out .3s;
    }

    small.form-text {
      opacity: 0;
      transition: all ease .3s;
    }

    &.active {
      small.form-text {
        opacity: 1;
      }

      textarea {
        max-height: 72px;
        transition: max-height ease-out .3s;
      }

      .form-actions {
        margin-top: 20px;
        max-height: 100%;
        transition: max-height ease-out .3s;
      }
    }
  }

  @media (max-width: 1024px) {
    .form-holder {
      small.form-text {
        display: none;
      }
    }
  }
}

@media (max-width: 1600px) {
  .was-validated .custom-select:valid,
  .custom-select.is-valid {
    padding-right: 0px !important;
    background: #f4f6f9 !important;
  }
}