h6 {
  letter-spacing: 1px;
}

.font-weight-medium {
  font-weight: 500;
}

.mb-4-5 {
  margin-bottom: 2.25rem;
}

.mb-2-5 {
  margin-bottom: 0.75rem;
}

.divider {
  height: 0;
  border-top: 1px solid $light-blue-1;
}

.badge-purple {
  background-color: $lightest-purple;
  color: $lighter-purple;
}

.badge-blue {
  background-color: $lightest-blue;
  color: $lighter-blue;
}

.tag {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  font-weight: $font-weight-bold;
  text-align: center;
  color: $white;
  line-height: 2.1rem;
  font-size: 0.875rem;
}

.tag--purple {
  background-color: $purple;
}

.tag--blue {
  background-color: $blue;
}

.text-bold {
  font-weight: $font-weight-bold !important;
}

.text-semi-bold {
  font-weight: $font-weight-semi-bold !important;
}

.clickable {
  cursor: pointer;
}

.dot {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  &.green {
    background-color: $green;
  }
  &.blue {
    background-color: $blue;
  }
  &.orange {
    background-color: $orange;
  }
}

.dot--black {
  background-color: $gray-700;
  color: $white;
}

.dot--white {
  border-style: solid;
  border-width: 2px;
  border-color: $gray-150;
  background-color: $white;
  color: $gray-700;
  line-height: 20px;
}

.dot--xs {
  width: 4px;
  height: 4px;
}

.dot--sm {
  width: 8px;
  height: 8px;
}

.dot--secondary {
  background-color: $secondary;
}

.bubble-wrapper {
  position: relative;
}

.bubble {
  position: absolute;
  top: -3px;
  right: -3px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  font-size: 10px;
  line-height: 14px;
  vertical-align: middle;
}

.bubble--danger {
  background-color: #f35050;
  color: white;
}

.text-gray-950 {
  color: $gray-950 !important;
}

.bg-white {
  color: $white;
}

.ml-2-5 {
  margin-left: 0.75rem;
}

.mt-2-5 {
  margin-top: 0.75rem;
}

.pt-2-5 {
  padding-top: 0.75rem;
}

.button-with-loader {
  min-width: 90px;
}

.row.small-gutter {
  margin: 0 -9px;
  & > [class^='col'] {
    padding: 0 9px;
  }
}

a {
  cursor: pointer;
}
