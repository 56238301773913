.job-edit-modal {
  background-color: $gray-125;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 999;
  width: 100%;
  transition: all ease 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-wrapper {
    overflow-y: auto;
    width: 80%;
    top: 40px;
    padding: 24px 30px 80px;
    bottom: 0;
    position: fixed;
    @include scrollbar;
  }
  .title-bar {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;
    &.top {
      position: fixed;
      top: 0;
      width: 80%;
      padding: 18px 34px;
      text-align: right;
      background-color: $gray-125;
      z-index: 2;
    }
    h4 {
      font-size: 14px;
      margin: 0;
    }
    .close-modal {
      cursor: pointer;
    }
  }
  .name-bar {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 36px;
    h2 {
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 2px;
    }
    .description {
      font-size: 16px;
    }
    .price {
      font-weight: bold;
      font-size: 18px;
      margin-left: 36px;
    }
  }
  .step-holder {
    padding-bottom: 24px;
    margin-bottom: 24px;
    &:not(:last-child) {
      border-bottom: 1px solid #b1bfd5;
    }
    .table-title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      a {
        text-decoration: underline;
      }
    }
    &.active {
      .title-bar {
        margin-bottom: 24px;
        h3 {
          margin-bottom: 8px;
        }
      }
    }
    .title-bar {
      margin-bottom: 0;
      h3 {
        font-size: 18px;
        margin-bottom: 0;
        font-weight: bold;
      }
      .selected-features {
        font-size: 14px;
        line-height: 26px;
        background-color: #fff;
        padding: 14px 24px;
        margin-top: 24px;
        b {
          font-weight: 500;
          width: 78px;
          display: inline-block;
          margin-right: 8px;
        }
      }
      a {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .pd-detail-box {
      display: inline-block;
      font-size: 14px;
      background-color: #fff;
      padding: 12px;
      margin-top: 24px;
      b {
        font-weight: 500;
        display: inline-block;
        margin-right: 8px;
      }
      p {
        margin-bottom: 0;
      }
      li {
        list-style: none;
        margin-right: -36px;
        .rd-deep-2 {
          padding: 0;
          background-color: transparent;
        }
        .container-fluid {
          padding: 0;
        }
      }
    }
    h5 {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 0;
    }
    .confirmation-wrapper {
      display: flex;
      justify-content: flex-end;
    }
    .selection-table {
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      border: 1px solid #b3b7b3;
      margin-bottom: 24px;
      h6 {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-align: center;
        border-bottom: 1px solid #b3b7b3;
        padding: 12px 0;
        margin-bottom: 0;
      }
      .selection-type {
        width: 40%;
        max-width: 40%;
        border-right: 1px solid #b3b7b3;
        h5 {
          padding: 12px 12px 0 12px;
          font-size: 14px;
          text-transform: none;
          letter-spacing: 0;
          margin-bottom: 0;
        }
        .options-holder {
          padding: 12px;
          .single-option {
            .option-label {
              border: 1px solid #d9dde3;
              padding: 0 12px;
              height: 36px;
              line-height: 36px;
              border-radius: 4px;
              display: inline-flex;
              align-items: center;
              margin-bottom: 8px;
              cursor: pointer;
              white-space: nowrap;
              .color {
                width: 24px;
                height: 24px;
                display: block;
                border-radius: 50%;
                border: 1px solid #d7d7d7;
                margin-right: 8px;
              }
            }
          }
        }
      }
      .selection-item {
        width: 30%;
        max-width: 30%;
        &:not(:last-child) {
          border-right: 1px solid #b3b7b3;
        }
        .selection-holder {
          padding-top: 12px;
          &.with-title {
            margin-top: 28.66px;
          }
          .form-group.title-spacer {
            margin-top: 64.66px;
          }
          .bv-no-focus-ring {
            &:focus {
              outline: none;
            }
          }
          .custom-control {
            display: block;
            width: 100%;
            padding-left: 0;
            height: 36px;
            padding-top: 6px;
            &:not(:last-child) {
              margin-bottom: 8px;
            }
            .custom-control-label {
              height: 22px;
              position: relative;
              width: 100%;
              span {
                display: none;
              }
              &:before {
                top: 0;
                left: 50%;
                margin-left: -12px;
                border: #d9dde3 solid 1px;
                background-color: transparent;
              }
              &:after {
                top: 0;
                left: 50%;
                margin-left: -12px;
              }
            }
          }
        }
      }
    }
    &.completed {
      .title-bar {
        margin-bottom: 0;
        position: relative;
        h3 {
          span {
            position: relative;
            &:after {
              content: ' ';
              background-image: escape-svg($custom-checkbox-indicator-icon-checked);
              background-position: center center;
              background-repeat: no-repeat;
              background-color: $black;
              border-radius: 50%;
              position: absolute;
              right: -44px;
              top: -2px;
              width: 24px;
              height: 24px;
            }

            &.invalid:after {
              background: none;
            }
          }
        }
      }
    }
  }
  .prescription-list,
  .pupil-distance-list,
  .lens-list {
    .rd-deep-1,
    .rd-deep-2 {
      position: relative;
      width: calc(100% - 44px);
      padding-bottom: 0.3rem;
      .rdd1-part__radio {
        right: -60px;
        left: auto;
        transform: translateY(-30px);
      }
      .rdd1-part--with-icon {
        .custom-radio {
          label {
            width: 22px;
            height: 22px;
            position: relative;
            top: -10px;
          }
        }
      }
    }
  }
  .prescription-detail-box {
    background-color: #fff;
    padding: 14px 24px;
    display: inline-block;
    margin-top: 24px;
    span {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
  form {
    .custom-select {
      border-color: #d9dde3;
    }
  }
  .footer-bar {
    position: fixed;
    right: 40px;
    left: 854px;
    bottom: 0;
    padding: 18px 40px;
    text-align: right;
    background-color: $gray-125;
    z-index: 2;
  }
  &.sticked {
    top: 40px;
    position: fixed;
    overflow-y: auto;
    @include scrollbar;
    padding-right: 35px;
  }
}
