html,
body,
#app,
.auth {
  height: 100%;
}

.auth {
  background-color: #303947;
}

.customers,
.jobs {
  padding-bottom: 2.25rem;
}

.page {
  padding: 0 2rem;
}

.page-header {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $gray-150;
}

.row--2px {
  @include make-row(4px);

  .col-3 {
    padding-left: 2px;
    padding-right: 2px;
  }
}

.row--6px {
  @include make-row(12px);

  div[class^='col'] {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.container-lg--custom {
  padding-left: 0;
  padding-right: 0;
}

.page-wrapper {
  padding: 0 40px;
}

.main-content-wrapper {
  padding: 0 40px;
  max-width: 928px;
  @media (max-width: 992px) {
    padding: 0 14px;
    .page-header {
      padding: 0 24px 24px 24px;
    }
    &.filters-holder {
      padding: 0 40px;
    }
    &.table-holder {
      padding: 0;
    }
  }
}


.main-content-wrapper-newsletter {
  padding: 0 40px;
  max-width: 1024px;
  @media (max-width: 992px) {
    padding: 0 14px;
    .page-header {
      padding: 0 24px 24px 24px;
    }
    &.filters-holder {
      padding: 0 40px;
    }
    &.table-holder {
      padding: 0;
    }
  }
}

@media (min-width: 992px) {
  .container-lg--custom {
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
  }
}

.text-medium {
  font-weight: 500 !important;
}

body {
  &.block-scroll {
    overflow-y: hidden;
  }
}
