.table {
  th,
  td {
    padding: 1.1rem 1.5rem;
  }

  th {
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    padding: 0.8rem 1.5rem;
  }

  thead th {
    border-bottom: none;
    color: $gray-550;
    &:focus {
      outline: none;
    }
  }
}

.table-filter {
  padding: 1.125rem;
  border-top: 1px solid;
  border-color: $gray-150;
  background-color: $gray-125;
  max-width: 985px;

  label {
    margin-bottom: 0;
    color: $gray-550;
  }

  .input-group-text,
  .form-control,
  .custom-select {
    background-color: $white;
  }

  .input-group-text {
    color: $gray-600;
  }

  .search-form {
    display: flex;
    .input-group {
      margin-right: 14px;
    }
    .dropdown {
      width: 18%;
      margin-right: 14px;
    }
  }
}

.row-details {
  position: relative;
  padding: 0.75rem;
  color: $gray-950;
  background-color: $gray-125;

  &::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 0;
    left: 70px;
    background-color: $gray-125;
    transform: translate(0, -50%) rotate(45deg);
  }
}

.rd-title {
  padding-top: 0.5rem;
  padding-left: 0.75rem;
}

.rd-list {
  li:not(:first-child) {
    margin-top: 12px;
  }
}

.rd-deep-1,
.rd-deep-2 {
  background-color: $white;
}

.rd-deep-1 {
  padding: 0.6875rem 0.5625rem;
  border-radius: 4px;

  &.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.rd-deep-2 {
  margin-top: 1px;
  padding: 1.25rem 0.5625rem 1rem;
}

.rd-deep-2-actions {
  background-color: #fff;
  padding: 0 24px 24px 24px;
  .buttons-wrapper {
    padding-top: 24px;
    border-top: 1px solid $gray-150;
    text-align: right;
  }
}

.rd-empty {
  background-color: $white;
  padding: 0.8125rem;
}

.rdd1-part {
  line-height: 2.75rem;
}

.rdd1-part--with-icon {
  position: relative;
}

.rdd1-part__icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.rdd1-part__content {
  margin-left: 2.875rem;
  min-height: 42px;
}

.rdd1-part--image {
  text-align: right;
}

.rdd2__footer {
  padding-top: 0.5rem;
  border-top: 1px solid $gray-150;
}

.collapsing {
  transition: none;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.b-table-details {
  position: relative;
  background-color: $gray-150;

  td {
    padding: 0;
  }

  &:focus {
    outline: none;
  }
}

.page-item {
  margin-right: 4px;
  margin-left: 4px;
}

.page-item:first-child,
.page-item:last-child {
  .page-link {
    border-radius: $btn-border-radius;
    background-color: #f4f7fd;

    &:hover {
      background-color: #d9dde3;
    }
  }
}

.page-item:not(:first-child):not(:last-child) {
  .page-link {
    border-radius: 50%;
  }
}
