
.customer-file-preview {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 170px;
  width: 210px;
  overflow: hidden;
  justify-content: center;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  img {
    object-fit: cover;
    height: 100%;
    max-width: 100%;
  }
}
.modal-file {
  .header {
    border-bottom: 1px solid #dee2e6 !important;
    padding: 1.5rem 1rem !important;
  }
  .footer {
    padding-right: 1.5rem !important;
  }
  .image-container {
    width: 750px;
    display: block;
    text-align: center;
    margin: 1rem auto;
  }
  .file-name {
    font-size: 16px;
    font-weight: 500;
  }
  .file-date {
    margin-left: 18px;
    font-size: 12px;
  }
}

.file-info {
  position: absolute;
  height: 120px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 100%);
  mix-blend-mode: normal;
  opacity: 0.8;
  border-radius: 0px 0px 4px 4px;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  padding: 0 10px;

  .basics {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    position: absolute;
    bottom: 10px;

    span {
      display: block;
    }
    .name {
      font-weight: 500;
    }
    .date {
      font-weight: 400;
      opacity: 0.6;
    }
  }

  .options {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 20px;
    height: 10px;
    position: absolute;
    right: 10px;
    bottom: 16px;

    &:hover {
      cursor: pointer;
    }

    span {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: white;
    }
  }
}
