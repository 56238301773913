.card--auth {
  border-radius: 4px;
  background-color: $gray-850;

  .card-body {
    padding: 2.25rem 3.75rem 1.5rem;
  }
}

.info-card-header {
  height: 64px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(25, 28, 33, 0.06);
  background-color: $gray-125;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 18px;

}

.info-card-label {
  height: 22px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: $gray-950;
  margin: 0;
}

.info-card-action{
  text-align: right;
}
