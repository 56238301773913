<template>
  <div>
    <div class="filters-holder">
      <div class="d-flex flex-row mt-3">
        <div class="flex-fill">
          <b-nav class="nav--customers">
            <b-nav-item active>
              <b-badge pill class="mr-2">{{ totalAll | numeral('0a') }}</b-badge
              >All
            </b-nav-item>
            <b-nav-item v-if="false"><b-badge pill class="mr-2">10</b-badge>Active</b-nav-item>
            <b-nav-item v-if="false"><b-badge pill class="mr-2">20</b-badge>New</b-nav-item>
            <b-nav-item v-if="false">
              <b-badge pill class="mr-2">32</b-badge>Due for Recall
            </b-nav-item>
          </b-nav>
        </div>
      </div>
    </div>
    <div class="table-filter">
      <div class="filters-holder">
        <b-form @submit.stop.prevent="submit">
          <b-row class="small-gutter">
            <b-col cols="6">
              <ClearableInput
                name="q"
                placeholder="Search customers by name, surname, email, mobile"
                v-model="q"
                @input-cleared="clear"
              >
                <template v-slot:prepend>
                  <b-input-group-text class="px-3">
                    <i class="di-search-18" />
                  </b-input-group-text>
                </template>
              </ClearableInput>
              <small class="text-danger" v-if="showValidationMessage && q && q.length < 3">
                Please enter at least 3 characters to search.
              </small>
              <b-form-group label="Date of birth" label-for="dob">
                <div class="clear-input">
                  <b-form-input id="dob" name="dob" v-model="dob" type="date"></b-form-input>
                  <button type="button" v-if="dob" @click="clearDate">
                    <i class="di-remove-10"></i>
                  </button>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Region"
                label-for="region"
                class="form-group--floating-label mb-0"
              >
                <b-select
                  name="region_id"
                  size="lg"
                  id="region"
                  v-model="region_id"
                  :options="$store.getters.regionOptions"
                />
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <div class="d-flex align-items-center h-100">
                <b-button type="submit" block size="lg" variant="primary">Search</b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import ClearableInput from '@/components/ClearableInput.vue';

export default {
  name: 'CustomersFilter',
  components: {
    ClearableInput,
  },
  data() {
    const { q, region_id, dob } = this.$route.query;
    return {
      q: q || null,
      dob: dob || null,
      region_id: region_id || null,
      showValidationMessage: false,
    };
  },
  props: {
    totalAll: Number,
  },
  methods: {
    clear() {
      this.q = null;
      this.showValidationMessage = false;
      this.submit();
    },
    clearDate() {
      this.dob = null;
      this.submit();
    },
    submit() {
      this.showValidationMessage = true;
      if (!this.q || this.q.length >= 3) {
        const query = {
          q: this.q,
          dob: this.dob,
          region_id: this.region_id,
          r: this._.random(0, 100),
          page: 1,
        };
        this.$router.push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            ...query,
          },
        });
      }
    },
  },
  watch: {
    $route({ query }) {
      const { q, region_id, dob } = query; //eslint-disable-line
      this.q = q;
      this.dob = dob;
      this.region_id = region_id || null; //eslint-disable-line
    },
    q() {
      this.showValidationMessage = false;
    },
  },
};
</script>

<style scoped>
.clear-input {
  display: flex;
}

.clear-input button {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  margin-right: 18px;
  background-color: #ebeef4;
  outline: none;
  border: none;
  border-radius: 50%;
  margin-top: 20px;
  margin-left: 5px;
}

.clear-input .di-remove-10 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  color: #414347;
}
</style>
