
@import '@/assets/scss/variables.scss';

.btn-select-filter {
  .btn {
    background-color: transparent !important;
    color: $gray-550;
    i {
      color: $gray-550;
    }
  }
  .dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .di-remove-10 {
    font-size: 10px;
    &:hover {
      color: red;
    }
  }
}

.outlet-abbr {
  width: 50px;
  display: inline-block;
}

.checkbox-filter {
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #adb5bd;
  }
  .custom-control-label {
    &::before {
      background-color: #fff;
      top: -4px;
      border-radius: 4px !important;
    }
    &::after {
      top: -4px;
    }
    span {
      color: #1b1f26;
      font-size: 14px;
    }
  }
}

.search-type-dropdown {
  .btn {
    min-width: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    
    &::after {
      margin-left: 8px;
    }
  }
}
