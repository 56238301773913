.toggle-switch {
  label {
    position: relative;
    display: inline-flex;
    align-items: flex-start;

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      visibility: hidden;

      &:checked + .switch {
        &:before {
          background-color: $green;
          left: 18px;
          transition: all ease .3s;
        }
      }
    }

    .switch {
      width: 40px;
      min-width: 40px;
      height: 24px;
      border-radius: 16px;
      background-color: #f4f6f9;
      border: 1px solid #979797;
      margin-right: 18px;
      position: relative;

      &:before {
        content: " ";
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        left: 4px;
        top: 2px;
        background-color: $red;
        transition: all ease .3s;
      }
    }

    .description {
      b {
        font-weight: 500;
        display: block;
        font-size: 14px;
        position: relative;
        top: 2px;
      }
    }
  }
}
