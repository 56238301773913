
.radio-buttons {
  display: flex;
  flex-direction: column;
  width: 275px;
  gap: 10px;
}

.radio-button {
  flex: 1;
  min-width: 80px;
  position: relative;
  cursor: pointer;
}

.radio-button input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-button-content {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.radio-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #dedede;
  margin-right: 10px;
  display: inline-block;
  transition: all 0.3s ease;
}

.radio-button input:checked + .radio-button-content .radio-circle {
  background-color: #007bff;
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #007bff;
}

.shape-icon {
  width: 70px;
  height: 24px;
  margin-right: 8px;
}

.radio-button:hover .radio-button-content {
  border-color: #007bff;
}

.shape-height-link {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: var(--primary);
  font-size: 14px;
  margin-top: 12px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  i {
    font-size: 16px;
  }
}
