@charset "UTF-8";

@font-face {
  font-family: "dresden-icons";
  src:url("../fonts/dresden-icons.eot");
  src:url("../fonts/dresden-icons.eot?#iefix") format("embedded-opentype"),
  url("../fonts/dresden-icons.woff") format("woff"),
  url("../fonts/dresden-icons.ttf") format("truetype"),
  url("../fonts/dresden-icons.svg#dresden-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "dresden-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: -2px;
}

[class^="di-"]:before,
[class*=" di-"]:before {
  font-family: "dresden-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: -2px;
}

.di-check-12:before {
  content: "\62";
}
.di-arrow-down-12:before {
  content: "\61";
}
.di-arrow-left-12:before {
  content: "\63";
}
.di-arrow-right-12:before {
  content: "\64";
}
.di-arrow-up-12:before {
  content: "\65";
}
.di-comment-18:before {
  content: "\66";
}
.di-create-18:before {
  content: "\67";
}
.di-filter-18:before {
  content: "\68";
}
.di-information-10:before {
  content: "\69";
}
.di-notification-bell-24:before {
  content: "\6a";
}
.di-search-24:before {
  content: "\6b";
}
.di-search-18:before {
  content: "\6c";
}
.di-remove-10:before {
  content: "\6d";
}
.di-profile-18:before {
  content: "\6e";
}
.di-more-24:before {
  content: "\6f";
}
.di-import-18:before {
  content: "\70";
}
.di-filled-arrow-down-8:before {
  content: "\71";
}
.di-filled-arrow-up-8:before {
  content: "\72";
}
.di-location-pin-24:before {
  content: "\73";
}

.svg-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  color: inherit;
  vertical-align: middle;
  fill: none;
  stroke: currentColor;
}

.svg-fill {
  fill: currentColor;
  stroke: none;
}

.svg-up {
  /* default */
  transform: rotate(0deg);
}

.svg-right {
  transform: rotate(90deg);
}

.svg-down {
  transform: rotate(180deg);
}

.svg-left {
  transform: rotate(-90deg);
}
